import React from 'react'
import styles from './myServiceRequest.module.css';
import IMAGES from '../../../../assets/images/images';
import { useNavigate } from 'react-router-dom';

const ServiceRequestList = ({  }) => {

  const navigate = useNavigate();
  const gotosrDetail = (requestId) => {
    navigate(`/ServiceRequestDetail/${requestId}`);
  }
    return (
        <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
        <div className={styles.header}>
                <div> <span  className={styles.SRid} onClick={()=>gotosrDetail()}>SR-20240719-21</span><span className={styles.status}>Submitted</span></div>
        </div>
        <div className={styles.summary}><span className={styles.issue}>Issue Summary -</span><span className={styles.summarydata}>Lorem ipsum dummy text lorem.  Lorem ipsum dummy text .</span></div>
          <div className={styles.titleContainer}>
          
          <div className={styles.DetailsDiv}>Submitted on<span className={styles.data}> 07 Aug 2023</span></div>
          {/* <div className={styles.border}></div>
          <div className={styles.DetailsDiv}>Signalling Issue<span className={styles.data}> sigtran</span></div> */}
          </div>
        
        </div>
       
      </div>
    );
  };
  

const ServiceRequestViewer = () => {
    return (
        <div>
          <ServiceRequestList/>
        </div>
    )
}
  
export default ServiceRequestViewer;
