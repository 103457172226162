 import VFUSerViewer from "./VFUserViewer";
import {connect} from "react-redux";
import { getUser } from "../../../../redux/actions/userManagement/userViewer.action";
import { getPredefinedRoles } from "../../../../redux/actions/userManagement/predefinedRoles.action";
import { updateUserPermissions } from "../../../../redux/actions/userManagement/updatePermissions.action";
import { openAlertPopup } from "../../../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => {
    return {
        userList:state.userViewer.data,
        isLoading:state.userViewer.isLoading,
        next:state.userViewer.next,
        predefinedRoles:state.predefinedRoles.roles,
        myRights:state.myRights.myRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (type,custID,searchKey,next=null) => {
            return dispatch(getUser(type,custID,searchKey,next))
        },
        getPredefinedRoles: (filter) => {
            return dispatch(getPredefinedRoles(filter))
        },
        updateUserPermissions: (payload,id) => {
            return dispatch(updateUserPermissions(payload,id))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        }
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VFUSerViewer);

