/*Redux connect*/
import { connect } from "react-redux";
import { SubmitRequst } from "../../../../redux/actions/userManagement/submitCustomerDetailsRequest.actions.js";
import NewCustomer from "./NewCustomer";
import { openAlertPopup } from "../../../../redux/actions/Alert/alert.action";


const mapStateToProps = (state) => {
    return {
   submitting: state.submitCustomerDetails.loading,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      submitRequest: (payload) => {
        return dispatch(SubmitRequst(payload))
      }, 
      openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      }
     
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);


