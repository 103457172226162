import React, { useState } from 'react'
import { Breadcrumbs } from '../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../components/banner/Banners';
import IMAGES from '../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../components/Container/Containers';
import styles from './incidentDetail.module.css';
import { useNavigate } from 'react-router-dom';
import AddComment from '../../addComment/addComment.container';

const ViewLessData = ({data}) => {
    return (
      <>
        <div className={styles.liveDetailsDiv}><span>Submitted on</span><div className={styles.data}>07 Aug 2023, 18:00 CET</div></div>
        <div className={styles.liveDetailsDiv}><span>Failure Type</span><div className={styles.data}>Call Failure</div></div>
        <div className={styles.liveDetailsDiv}><span>Sub Category</span><div className={styles.data}>Low NER</div></div>
        <div className={styles.liveDetailsDiv}><span>Problem Statement</span><div className={styles.data}>Problem Statement</div></div>
      </>
    );
  };
const ViewMoreData = ({ data }) => {
    return (
        <>
         <div className={styles.liveDetailsDiv}><span>Calling Number</span><div className={styles.data}>88888888</div></div>
         <div className={styles.liveDetailsDiv}><span>Called Number</span><div className={styles.data}>999999999</div></div>
         <div className={styles.liveDetailsDiv}><span>Priority</span><div className={styles.data}>P1 (complete service outage )</div></div>
         <div className={styles.liveDetailsDiv}><span>Attachements uploaded</span><div className={styles.data}>Attachements uploaded</div></div>
        </>
    );
  };

  
const DetailList = ({  }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    
  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/incident-management`);
  }
  
    return (
        <div className={styles.headero}>
        <div className={styles.back}>
             <span><img className={styles.backbutton} src={IMAGES.back} onClick={()=>back()} /></span><span className={styles.id}>SR-20240719-21</span><span className={styles.status}>Submitted</span>
        </div>
      <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
      
          <div className={styles.titleContainer}>
           <ViewLessData/>
          </div>
          {!isOpen && (
            <button type="button" className={styles.open} onClick={toggleContent}>
              View More
              <img
                className={styles.arrowdown}
                src={IMAGES.arrowdown}
                alt="arrow"
              />
            </button>
          )}
        </div>
        <div className={`${styles.content} ${isOpen ? styles.active : ""}`}>
          <div className={styles.contentInner}>
            <ViewMoreData/>
          </div>
          {isOpen && (
            <button
              type="button"
              className={styles.close}
              onClick={toggleContent}
            >
              View Less
              <img className={styles.arrowup} src={IMAGES.arrowup} alt="arrows" />
            </button>
          )}
        </div>
      </div>
      </div>
    );
  };
  
  const CommentDetail = ({  }) => {
    return (
      <div  className={styles.commentContainer}>
                <div  className={styles.initalshead}>
                   <div  className={styles.initaltex}><span className={styles.inital}>PS</span><span className={styles.initatname}>Prerna Sinha</span></div>
                   <div  className={styles.attachment}>Posted on</div>
                </div>
              <div  className={styles.comment}>Lorem ipsum dolor sit amet consectetur. Donec in tellus nam tellus mus in vel blandit elementum. Malesuada proin tristique porta quis praesent. Lorem ipsum dolor sit amet consectetur. Donec in tellus nam tellus mus in vel blandit elementum. Malesuada proin tristique porta quis praesent.</div>
              <div className={styles.attachment}></div>
           </div>
    );
  }


const IncidentDetail=()=> {
   
  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Incident Management"}></Banner>
        <ChildContainer>
           <DetailList/>
           <div  className={styles.listhead}>
                 <div  className={styles.initaltext}>Comment History</div>
                  <div> <AddComment source={'incDetail'}/></div>
           </div>
          <CommentDetail/>
        </ChildContainer>
  
    </Container>
  )
};

export default IncidentDetail
