import React, { useState,useEffect} from "react";
import style from "./sidebar.module.css";
import IMAGES from "../../assets/images/images";


export const Sidebar = ({ children, isOpen, onClose, headerTxt, ...rest }) => {
  
  return (
    <>
      {isOpen ?
      <React.Fragment>
      <div role="button" className={style.backdrop} onClick={onClose} />
      <div className={`${style.sidenav} ${style.open}`}>
        <div className={style.header}>
          <div className={style.headerTxt}>{headerTxt}</div>
          <button className={style.closeBtn} onClick={onClose}>
            <img
              width="24px"
              height="24px"
              src={IMAGES.close}
              alt="x mark indicating close icon"
            />
          </button>
        </div>
        <div className={style.sidemenuContainer}>{children}</div>
      </div>
      </React.Fragment>:<></>
      }
    </>
  );
};

export const closeSideBar = () => {};
