import React, { useEffect, useState, useRef } from "react";
import styles from "./liveServices.module.css";
import { ServiceName } from "../../components/list/list";
import IMAGES from "../../assets/images/images";
import { Search } from "../../components/search/search";
import { Loader } from "../../components/loader/Loader";
import { filter } from "@ucast/mongo2js";
import { Link } from "react-router-dom";
import CAN from '../../casl/can';  
import {Action , Resource} from '../../casl/constants';  
import { Banner } from "../../components/banner/Banners";
import {USER_TYPE} from "../../utils/constants/userConstants";
import { SERVICE_STATUS_OPTIONS } from "../../utils/constants/serviceConstants";
import { Graphs } from "../../utils/constants/insightsConstants";
import NewService from "../../modules/newService/add/newService.container";
import { openNewServiceForm,closeNewServiceForm,setServiceActiveTab } from "../../redux/actions/newService/service.actions";
//import { use } from "echarts/types/src/extension.js";
import * as XLSX from 'xlsx';
import Overlay from "../dashboard/graphs/overlay/Overlay";
import { Formik, useField, Form, Field } from 'formik';
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../components/label/Labels";
import DropDown from "../../components/dropDown/DropDown";
import { SERVICE } from "../../api/constants";

const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.xlsx`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(url);
};


const AmmendService = ({data, upgradeServiceRequest}) => {   
  return (
    <img className={styles.ammendIcon} src={IMAGES.ammendService} onClick={() => upgradeServiceRequest(data)} />
  );
}

const TitleStatus = ({ data, isInternalUser, upgradeServiceRequest, serviceList }) => {
  return (
    <div className={styles.headservicename}>
      {!isInternalUser ? (
        <div className={styles.serviceTitleContainer}>
          <div className={styles.servicetitle}>{data.ipx_service?.toUpperCase()}</div>
          <div className={styles.serviceregion}>({data.cp_name_region})</div>
        </div>
      ) : (
        <div className={styles.serviceTitleContainer}>
          <div className={styles.servicetitle}>{data.cp_name}</div>
          <div className={styles.serviceregion}>({data.cp_name_region})</div>
        </div>
      )}
     <div className={styles.statusContainer}>
	        <label
	          className={styles.serviceStatus}
	          style={{
	            backgroundColor:
	              data.bgp_status === "No Shutdown"
	                ? "#238424"
	                : data.bgp_status === "Value is missing"
	                ? " #E60000"
	                : "#007C92",
	          }}
	        >
	          {data.bgp_status === "No Shutdown" ? (
              <span>Live</span>
            ) : ( 
                <span>{data.bgp_status}</span>
            )}
	        </label>
      </div>
      {!isInternalUser ? (<AmmendService data={data} upgradeServiceRequest={upgradeServiceRequest} serviceList={serviceList} />): <></>
      }
    </div>
  );
};

const LiveServicesData = ({ data, isInternalUser,PopsLocation}) => {

  const getLocation=(pe_ref)=>{
    const obj = PopsLocation.find(({ hostName }) => hostName.toLowerCase() === pe_ref.toLowerCase());
    return obj?.city;
  }
  
  return (
    <>
      {isInternalUser ? (
        <div className={styles.liveDetailsDiv}>
          <span>Service</span>
          <p>{data.ipx_service?.toUpperCase()}</p>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.liveDetailsDiv}>
        <span>VLAN ID</span>
        <p>{data.vlan}</p>
      </div>
      <div className={styles.liveDetailsDiv}>
        <span>Router City</span>
        <p>{getLocation(data.pe_ref)}</p>
      </div>
      <div className={styles.liveDetailsDiv}>
        <span>Router Name</span>
        <p>{data.pe_ref}</p>
      </div>
      <div className={styles.liveDetailsDiv}>
        <span>Allocated Capacity (Mbps)</span>
        <p>{data.logical_capacity_mbps}</p>
      </div>
      {!isInternalUser ? (
        <div className={styles.liveDetailsDiv}>
          <span></span>
          <p></p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
const LiveServicesViewMoreData = ({ data }) => {
  let weatherMapParams={
    tab:Graphs.WEATHERMAPS,
    service_types:data?.ipx_service,
    cp_names:data?.cp_name_region
  }
  let _weatherMapParams=JSON.stringify(weatherMapParams)
  return (
    <>
      <div className={styles.liveDetailsviewmore}>
        <span>Physical Capacity (Gbps)</span>
        <p>{data.physical_capacity_gbps}</p>
      </div>
      <div className={styles.liveDetailsviewmore}>
        <span>VDF Internal Ref.</span>
        <p>{data.ref3c}</p>
      </div>
      <div className={styles.liveDetailsviewmore}>
        <span>BGP IP Address Customer</span>
        <p>{data.bgp_neighbor}</p>
      </div>
      <div className={styles.liveDetailsviewmore}>
        <span>BGP IP Address VDF</span>
        <p>{data.vf_bgp_ip}</p>
      </div>
      <div className={styles.liveDetailsviewmore}>
        <span>Link Utilisation</span>
        <p>
          {" "}
         <Link to={`/insights/${_weatherMapParams}`} target="_blank"  className={styles.link}>
             View Link
       </Link> 
        </p>
      </div>
    </>
  );
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {  
  let _options = options.map((item) => ({ value: item.value, label: item.name }));
  const [field, meta, helpers] = useField(props);
  
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
      <Label text={title} />
      </div>
      <DropDown
        options={_options}   
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          helpers.setValue(value);             
        }}
        value={
          (_options && field.value)
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const SelectedFilters=({selectedFilters,...props})=>{ 
  let statusName = selectedFilters?.status?(selectedFilters?.status == 'No Shutdown')?'Live':selectedFilters?.status:'NOT SELECTED';
  return(
      <div className={styles.selectedFilters}> 
      <div className={styles.labelShowFilter}>
            <span>Showing results for &nbsp;</span>
      </div>       
      {
        selectedFilters?.user_type == USER_TYPE.INTERNAL ?
          <div className={styles.selectedItem}>
            <span className={styles.label}>Customer</span><span className={styles.value}>{selectedFilters?.customer ? selectedFilters?.customer.toUpperCase() : "NOT SELECTED"}</span>
          </div> : <></>
      }         
      <React.Fragment>             
          <div className={styles.selectedItem}>
              <span className={styles.label}>Service</span><span className={styles.value}>{selectedFilters?.serviceType ? selectedFilters?.serviceType.toUpperCase() : "NOT SELECTED"}</span>
          </div> 
          <div className={styles.selectedItem}>
              <span className={styles.label}>Status</span><span className={styles.value}>{statusName?.toUpperCase()}</span>
          </div>
      </React.Fragment>
         
    </div>
  )
}
const sortArray =(array)=>{
  if (array.length > 0){
  const newArray=JSON.parse(JSON.stringify(array)); // No change in original array
  return newArray.sort((a,b)=>a?.toUpperCase().localeCompare(b?.toUpperCase()));  // Ascending alphabetical
  }
  return [];
}
const FilterContainer = ({ defaultFilters,resetFilters,filterData, customerList, serviceList, statusList, setStatusFilter, setCustFilter, setServiceFilter, ...props }) => {
  let obj = {
    width: "auto"
  }  
  

  let _customerOptions  =  ["ALL", ...customerList]; 
  let _serviceTypeOptions  =  ["ALL", ...serviceList]; 
  let _statusOptions  =  ["ALL", ...statusList];
  let customerOptions  = sortArray(_customerOptions?_customerOptions:[]).map(key => ({name: key, value: key}));
  let serviceTypeOptions  = sortArray(_serviceTypeOptions?_serviceTypeOptions:[]).map(key => ({name: key?.toUpperCase(), value: key}));
  let statusOptions = sortArray(_statusOptions?_statusOptions:[]).map(key => ({name: key?.toUpperCase(), value: key}));

  Object.keys(statusOptions).forEach((option) => {
    console.log("status name",statusOptions[option].name);
    if (statusOptions[option]['value'] == 'No Shutdown'){
      statusOptions[option]['name'] = 'LIVE';
    }
})
console.log("stuas option", statusOptions)
  let _initialValues = defaultFilters; 
  
  const handleServiceTypeChange=(value)=>{
      
  }
  const handleStatusChange=(value)=>{
      
  }
  const handleCustomerChange=(value)=>{
      
  }

  return (
      <div>
          <Formik
              initialValues={_initialValues}              
              onSubmit={async (values) => {
                let queryString = '';
                queryString += values?.customer?"&customer="+encodeURIComponent(values?.customer):'';
                queryString += values?.serviceType?"&serviceType="+encodeURIComponent(values?.serviceType):''; 
                queryString += values?.status?"&status="+encodeURIComponent(values?.status):'';
                  filterData('filter', queryString);
                  setStatusFilter(values?.status);
                  setCustFilter(values?.customer);
                  setServiceFilter(values?.serviceType);                               
              }}
          >
              {({ isValid, dirty, values,setFieldValue }) => (
                  <Form>                 
                     <React.Fragment>
                         {
                             defaultFilters.user_type===USER_TYPE.INTERNAL ?
                                 <React.Fragment>
                                     <SelectField
                                        title={"Customer"}
                                        name="customer"
                                        options={customerOptions}
                                        customStyles={obj}
                                        onChange={handleCustomerChange}
                                    />
                                 </React.Fragment> : <></>
                         }
                     </React.Fragment>                   
                        
                 <SelectField
                     title={"Service"}
                     name="serviceType"
                     options={serviceTypeOptions}
                     customStyles={obj}
                     onChange={handleServiceTypeChange}
                   
                 />
                  <SelectField
                     title={"Status"}
                     name="status"
                     options={statusOptions}
                     onChange={handleStatusChange}  
                     customStyles={obj}                  
                 />
                 <div className={styles.formikBtnContainer}>
                   <button className={styles.formikBtnClear} onClick={resetFilters}>Reset</button>
                    <button  type="submit" className={styles.formikBtnSubmit}>Apply</button>
                 </div>
             </Form>
              )}
          </Formik>

      </div>
  )
}
const SearchBarLiveServices = ({
  searchKey,
  getSearchKeyValue,
  searchKeyValue,
  filterData,
  onKeyDownHandler,
  handleExportToExcel,
  resetFilters,
  defaultFilters,
  childRef,
  services,
  customerList,   
  setStatusFilter,
  setCustFilter,
  setServiceFilter,
  filterSearch,
  statusOptions
}) => {
  const inputSearchStyle = `${styles.searchLiveServices} `;
  return (
    <div>
    <div className={styles.livesearchBar}>
      <Search
        placeholder={"Search"}
        style={inputSearchStyle}
        filterData={filterData}
        getSearchKeyValue={getSearchKeyValue}
        value={searchKeyValue}
        onKeyDown={onKeyDownHandler}
      />
       <div className={styles.filterContainer}>
          <Overlay ref={childRef} className={styles.applyFilterBtn} >
            <FilterContainer 
              defaultFilters={JSON.parse(JSON.stringify(defaultFilters))}
              resetFilters={resetFilters}
              filterData={filterData}
              serviceList={services?.length?services:[]}
              customerList={customerList?.length?customerList:[]}
              filter={defaultFilters}
              statusList={statusOptions?.length?statusOptions:[]}
              setStatusFilter={setStatusFilter}
              setCustFilter={setCustFilter}
              setServiceFilter={setServiceFilter}
              />
          </Overlay>
        </div>
        {(!CAN(Action.DOWNLOAD, Resource.LIVE_SERVICES))?<></>:
      <div >
        <button className={styles.download} onClick={handleExportToExcel}>Download <span><img className={styles.downloadlogo} src={IMAGES.downloadExcel}/></span></button>  
      </div>
         } 
    </div>
    {filterSearch === false?<></>:
      <div className={styles.selectedFilterContainer}>
        <SelectedFilters selectedFilters={defaultFilters}/>
      </div>}
    </div>
  );
};

const LiveServiceItem = ({ liveServiceDetails, isInternalUser,PopsLocation, upgradeServiceRequest, serviceList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}>
        <TitleStatus data={liveServiceDetails} isInternalUser={isInternalUser} upgradeServiceRequest={upgradeServiceRequest} serviceList={serviceList}  />
        <div className={styles.titleContainer}>
          <LiveServicesData data={liveServiceDetails} isInternalUser={isInternalUser} PopsLocation={PopsLocation}/>
        </div>
        {!isOpen && (
          <button type="button" className={styles.open} onClick={toggleContent}>
            View More
            <img
              className={styles.arrowdown}
              src={IMAGES.arrowdown}
              alt="arrow"
            />
          </button>
        )}
      </div>
      <div className={`${styles.content} ${isOpen ? styles.active : ""}`}>
        <div className={styles.contentInner}>
          <LiveServicesViewMoreData data={liveServiceDetails} />
        </div>
        {isOpen && (
          <button
            type="button"
            className={styles.close}
            onClick={toggleContent}
          >
            View Less
            <img className={styles.arrowup} src={IMAGES.arrowup} alt="arrows" />
          </button>
        )}
      </div>
    </div>
  );
};

const LiveServices = (props) => {
  const childRef = useRef();
  const { liveServicesData, isLoading, liveServicesFilter, isFilterLoading} = props;  
  let [searchKeyValue, setSearchKeyValue] = useState(""); 
  let [initialValues, setInitialValues] = useState({});  
  let [statusFilterValue, setStatusFilterValue] = useState('');
  let [custFilterValue, setCustFilterValue] = useState('');
  let [serviceFilterValue, setServiceFilterValue] = useState('');
  let [filterSearch, setFilterSearch] = useState(false);
  let [filterSearchValue, setFilterSearchValue] = useState('');

  let defaultFilters={    
    serviceType: serviceFilterValue,
    customer: custFilterValue,  
    status: statusFilterValue,  
    user_type:props.myRights?.userType,    
}
  const upgradeServiceRequest = (data) => {  
    setInitialValues(data); // open form with data
    props.openNewServiceForm(data);
    props.setIsAmendMode(true);
  }

  const getSearchKeyValue = (e) => {
    setSearchKeyValue(e.target.value);
  };

  const filterData = (label, value) => {
    let filter, searchKey = '';   
    if (label =='searchKey'){
      searchKey = searchKeyValue;     
      setFilterSearch(false)
    }
    if (label =='filter'){
      setFilterSearch(true)
      filter = value;  
      setFilterSearchValue(value);      
      childRef.current.handleClick(); /// TO CLOSE APPLY FILTER POP UP     
    }
       
    if (label =='searchKey'){
      setStatusFilter('');
      setCustFilter('');
      setServiceFilter('');
      setSearchKeyValue(searchKey);      
    }
    //let searchKey = searchKeyValue.trim();
    let cp_name = props.myRights?.cpName;
    props.getLiveServices(cp_name, searchKey,filter);
  };

  const onKeyDownHandler = (e) => {   // filter data on enter event
    if (e.key === "Enter"){
      setFilterSearch(false)
      setStatusFilter('');
      setCustFilter('');
      setServiceFilter('');
      setFilterSearchValue('');   
      filterData('searchKey', searchKeyValue);  
    }
  };

  const handleExportToExcel = () => {
   const PopsLocation = props.pops;
  
    props.downloadLiveServicesList(filterSearchValue,searchKeyValue).then((result) => {

      const modifyliveserviceData = result.downloadedData.map((data) => {
        const getLocation=(pe_ref)=>{
          const obj = PopsLocation.find(({ hostName }) => hostName.toLowerCase() === pe_ref.toLowerCase());
          return obj?.city;
        }
        return {
          "Customer": data.cp_name,
          "Service": data.ipx_service.toUpperCase(),
          "VLAN ID": data.vlan,
          "Router City":getLocation(data.pe_ref),
          "Router Name": data.pe_ref,
          "Allocated Capacity (Mbps)": data.logical_capacity_mbps,
          "Physical Capacity (Gbps)": data.physical_capacity_gbps,
          "VDF Internal Ref.": data.ref3c,
          "BGP IP Address Customer": data.bgp_neighbor,
          "BGP IP Address VDF": data.vf_bgp_ip,
          "Status": data.bgp_status === "No Shutdown" ? "Live" : data.bgp_status,
        }; 
      });
      
      // console.log("result",result);
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear().toString().slice(-2)}`;
    exportToExcel(modifyliveserviceData, `LiveServices-${formattedDate}`);
  })
  .catch((error) => {   
  }); 
};


const resetFilters=()=>{
  setStatusFilter('');
  setCustFilter('');
  setServiceFilter('');
  setFilterSearch(false)
  childRef.current.handleClick();
  filterData('','',null);
}
const setStatusFilter = (value) => {
  setStatusFilterValue(value);
}
const setCustFilter = (value) => {
  setCustFilterValue(value);
}
const setServiceFilter = (value) => {
  setServiceFilterValue(value);
}
  useEffect(() => {
    let searchKey = "";
    let cp_name =props.myRights?.cpName;   
    props.getLiveServices(cp_name, searchKey,'');
    props.getLiveServicesFiltersValue();
     
  }, []);

  useEffect(() => {   
    if (filterSearch == true){
      setSearchKeyValue('');
    } else {
      setFilterSearchValue('');
    }
    
  }, [filterSearch]);
 
  return (
    <div>
      <SearchBarLiveServices
        getSearchKeyValue={getSearchKeyValue}
        searchKeyValue={searchKeyValue}
        filterData={filterData}
        onKeyDownHandler={onKeyDownHandler}
        handleExportToExcel={handleExportToExcel}
        resetFilters={resetFilters} 
        defaultFilters={defaultFilters} 
        childRef={childRef} 
        services={props.serviceTypesDrodownOptions} 
        customerList={props.customerDrodownOptions}        
        setStatusFilter={setStatusFilter} 
        setCustFilter={setCustFilter} 
        setServiceFilter={setServiceFilter} 
        filterSearch={filterSearch}
        statusOptions = {props.statusDrodownOptions}

      />
      {isLoading ? <Loader /> :
        liveServicesData?.length > 0 ?
          <React.Fragment>
            {liveServicesData.map((liveServiceDetails) => (
              <LiveServiceItem
                liveServiceDetails={liveServiceDetails}
                isInternalUser={props.myRights?.userType === USER_TYPE.INTERNAL ? true : false}
                PopsLocation={props.pops} upgradeServiceRequest={upgradeServiceRequest}
              />
            ))}
          </React.Fragment> : <div className={styles.emptyContainer}>No service found</div>
      }
     <NewService initialValue={initialValues} fromLiveService={true}></NewService>
    </div>
  );
};

export default LiveServices;
