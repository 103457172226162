import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../components/Container/Containers";
import { Banner } from "../../components/banner/Banners";
import { Breadcrumbs } from "../../components/breadcrumbs/Breadcrumbs";
import IMAGES from "../../assets/images/images";
import { Tabs } from "../../components/tabs/Tabs";
import DiameterInOutMessageGraph from "./graphs/diameter/DiameterInOutMessageGraph";
import DiameterInOutMessageBySigNodeGraph from "./graphs/diameter/DiameterInOutMessageBySigNodeGraph";
import SigtranInOutMessageGraph from "./graphs/sigtran/SigtranInOutMessageGraph";
import SigtranInOutMessageBySigNodeGraph from "./graphs/sigtran/SigtranInOutMessageBySigNodeGraph";
import {USER_TYPE} from "../../utils/constants/userConstants";
import WeatherMapPeakInOutByRouterGraph from "./graphs/weatherMaps/WeatherMapPeakInOutByRouterGraph";
import WeatherMapVolumeInOutByRouterGraph from "./graphs/weatherMaps/WeatherMapVolumeInOutByRouterGraph";
import { Graphs } from "../../utils/constants/insightsConstants";
import { useParams } from 'react-router';
import WeatherMapCommonFilter from "./graphs/weatherMaps/WeatherMapCommonFilter";

//import DiameterGraphs from "./graphs/DiameterGraphs";
//import SigtranGraphs from "./graphs/SigtranGraphs";
//import WeatherMapsGraphs from "./graphs/WeatherMapsGraphs";


const DiameterGraphs=()=>{
  return(
    <React.Fragment>
      <DiameterInOutMessageGraph/>
      <DiameterInOutMessageBySigNodeGraph/>
    </React.Fragment>
  )
}

const SigtranGraphs=()=>{
  return(
    <React.Fragment>
      <SigtranInOutMessageGraph/>
      <SigtranInOutMessageBySigNodeGraph/>
    </React.Fragment>
  )
}
const WeatherMapsGraphs = ({params}) => {
  return (
    <React.Fragment>
      <WeatherMapPeakInOutByRouterGraph params={params}/>
      <WeatherMapVolumeInOutByRouterGraph params={params}/>
    </React.Fragment>
  )
}

const Dashboard = (props) => {
  const [activeTab, setActiveTab] = useState("");
  const params= useParams();
  let weatherMapParams={};
  if(params.hasOwnProperty('data')){
    let data=JSON.parse(params.data);
    if(data?.tab===Graphs.WEATHERMAPS){
      weatherMapParams.service_types=data?.service_types.toLowerCase();
      weatherMapParams.cp_names=data?.cp_names;
    }
  }
  const breadcrumbsOption = [
    { label: "Insights", link: "" }
  ];
  const tabOption = [Graphs.WEATHERMAPS,Graphs.DIAMETER, Graphs.SIGTRAN];
  const getActiveTab = (tab) => {
    setActiveTab(tab);
  }

  useEffect(()=>{
    let cp_name=props.myRights?.cpName; 
    let  userType =props.myRights?.userType;
    if(userType==USER_TYPE.INTERNAL){
      props.getDiaFinalTypeandFinalDist(cp_name);
      props.getSigFinalTypeandFinalDist(cp_name);

    }else{
      props.getDiaSigNodeandConectionId(cp_name);
      props.getSigSigNodeandConectionId(cp_name);

    }
    props.getWeatherMapFiltersValue(cp_name);
  },[])

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Insights"}></Banner>
      {/*<ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
  </ChildContainer>*/}
      <Tabs option={tabOption} defaultActiveTab={Graphs.WEATHERMAPS} getActiveTab={getActiveTab} />
      <ChildContainer>
        {
          activeTab===Graphs.DIAMETER?
          <DiameterGraphs/>:
          activeTab===Graphs.SIGTRAN?
          <SigtranGraphs/>:
          activeTab===Graphs.WEATHERMAPS?
          <div>
          <WeatherMapCommonFilter params={weatherMapParams}/>
          <WeatherMapsGraphs params={weatherMapParams}/></div>
          :<></>
        }
      </ChildContainer>
    </Container>
  )
};

export default Dashboard;
