import CustomerViewer from "./CustomerViewer";
import { connect } from "react-redux";
import { getCustomerList } from "../../../../redux/actions/userManagement/customer.action";

const mapStateToProps = (state) => {
  return {
    list: state.customerList.list,
    next: state.customerList.next,
    isLoading: state.customerList.loading,
    myRights:state.myRights.myRights,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerList: (status = "", searchKey = "", next="") => {
      return dispatch(getCustomerList(status, searchKey, next));
    }
  

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewer);
