export const customGroupBy = (array,key) => {
    return array.reduce((acc, currentValue) => {
        let groupKey = currentValue[key];
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
    }, {});
}

export const getLabelFromValue=(array,value)=>{
    var result = array.find(item => item.value === value);
    return result?.label;
}