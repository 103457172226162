import {
  BASE_URL,
  GET,
  RIGHTS_ME,
  IAM_PRE_DEFINED_ROLES,
  LIST_CUSTOMER
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken,getAccessTokenForceRefresh } from "../../authActions/authActions";

export const fetchMyRights = async () => {
  //https://jira.sp.vodafone.com/browse/CUP-371
  const token = await getAccessTokenForceRefresh();  // do not use this method in other api calls to get access token , we have calling this method here to get new token to handle refresh event on email verify (ForceRefresh)
  const resource = `${BASE_URL}${RIGHTS_ME}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `Bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const fetchCustomerList = async (cp_name="", searchKey="", next="") => {
  const token = await getAccessToken();
  let resource=`${BASE_URL}${LIST_CUSTOMER}`;   
    
  let array = [
    {
      key:"tadigId",
      value:cp_name
    },
    {
      key:"searchkey",
      value:encodeURIComponent(searchKey)
    },
    {
      key:"next",
      value:next
    }
  ]
  let queryString=``;
  array.forEach((item)=>{
    if(item.value){
      if(queryString){
        queryString=queryString+`&${item.key}=${item.value}`
      }else{
        queryString=`${item.key}=${item.value}`
      }
    }
  })
  
  if(queryString){
      resource=`${resource}?${queryString}`;
  }



  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `Bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

export const submitCustomerDetails = async(payload) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${LIST_CUSTOMER}`;
  return fetch(resource, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "userauth" : `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  })
  .then(statusParsing)
  .then(jsonParsing);
};


export const fetchPredefinedRoles = async(filter) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${IAM_PRE_DEFINED_ROLES}?flag=names&filter=${filter}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth" : `Bearer ${token}`
    },
  })
  .then(statusParsing)
  .then(jsonParsing);
};



export const fetchCustomerDetails = async(customerId) => {
  const token = await getAccessToken();
  const resource = `${BASE_URL}${LIST_CUSTOMER}/${encodeURIComponent(customerId)}`;
  return fetch(resource, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userauth" : `Bearer ${token}`
    },
  })
  .then(statusParsing)
  .then(jsonParsing);
};




