import React, { useId, useEffect, useState } from "react";
import { ChildContainer, Container } from "../../../../components/Container/Containers";
import styles from "./VFUserViewer.module.css";
import { Search } from "../../../../components/search/search";
import NewVFUser from "../add/NewVFUser.container";
import { Loader } from "../../../../components/loader/Loader";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import IMAGES from "../../../../assets/images/images";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
// import { Alert } from "../../../../components/alert/alerts";
import InfiniteScroll from "react-infinite-scroll-component";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";



const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const SearchList = ({getSearchKeyValue, filterData,onKeyDownHandler}) => {
  const inputSearchStyle = `${styles.searchList} `;
  return (
    <div className={styles.searchBar}>
      <Search 
        placeholder={"Search"}
        style={inputSearchStyle}
        filterData={filterData}
        getSearchKeyValue={getSearchKeyValue}
        onKeyDown={onKeyDownHandler}
      />
    </div>
  );
};

const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span>View More</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.expanded}>
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>

      {children}
    </div>
  );
};

const VfHeader = ({ getSearchKeyValue, filterData, searchKeyValue,onKeyDownHandler }) => {

  return (
    <div className={styles.headContainer}>
      <div className={styles.vfuser}>
        <SearchList
          getSearchKeyValue={getSearchKeyValue}
          searchKeyValue={searchKeyValue}
          filterData={filterData}
          onKeyDownHandler={onKeyDownHandler}
        />
      </div>
      <div className={styles.addNew}><NewVFUser /></div>
    </div>
  )
}

const Switch = ({isChecked}) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={isChecked} disabled/>
      <span className={styles.slider}></span>
    </label>
  );
};

const UserList = ({ data, predefinedRolesData,updateUserPermissions, openAlertPopup }) => {
  let [isAlert, setIsAlert] = useState(false);
  let [isEditPermission, setIsEditPermission]=useState(false);
  let [showLoader, setShowLoader]=useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  const validationSchema = Yup.object().shape({
    predefinedRoles: Yup.array().min(1)
  });
  let initialValues = {
    predefinedRoles: data?.predefinedRoles.length>0?data.predefinedRoles:[],
  }

  const editPermission =()=>{
    setIsEditPermission(!isEditPermission);
  }

  return (
    <div className={styles.VfUserListContainer}>

      <div className={styles.VfUserList}>
        <span className={styles.spanlabel}>Name</span>
        <p>{data.displayName}</p>
      </div>
      <div className={styles.VfUserList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{data.email}</p>
      </div>
      {/* <div className={styles.VfUserList}>
        <span className={styles.spanlabel}>Mobile Number</span>
        <p>{data.phoneNumber}</p>
      </div> */}
      <div className={styles.VfUserList}>
        <span className={styles.spanlabel}>Onboarded On</span>
        <p>{formatSecondsToDate(data.createdAt)}</p>
      </div>
      <div className={styles.VfUserList}>
        <span className={styles.spanlabel}>Status </span>
        <p>{""}Inactive <span className={styles.spanSwitch}><Switch isChecked={data.status === "active" ? true : false} /></span>Active</p>
      </div>
      <ExpandableArea>
        <div style={{ width: "100%" }}>
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (values, { resetForm }) => {
                  values.userType=data.userType;
                  setShowLoader(true);
                  updateUserPermissions(values,data.uid).then((result) => {
                    if (result.status) {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "User permissions updated successfully.",
                      //   image: IMAGES.success
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "User permissions updated successfully!",
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    } else {
                      let error_msg = result?.error;
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: error_msg,
                      //   image: IMAGES.error
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error_msg,
                        image: IMAGES.error
                      });
                      setShowLoader(false);
                    }
                  })
                    .catch((error) => {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "Something went wrong.",
                      //   image: IMAGES.error
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "Something went wrong.",
                        image: IMAGES.error
                      });
                      setShowLoader(false);
                    });

                

              }}
            >
              {({ isValid, dirty ,submitForm}) => (
                <Form className={styles.form}>
                   <div className={styles.sectionContainer}>
                      <div  className={styles.sectionTitle}>Modules Assigned</div>
                      {
                        showLoader?
                        <div className={styles.loaderContainer}><Loader style={styles.loaderContainer}/></div>:
                        isEditPermission?
                          <div><img src={IMAGES.save} className={styles.Icon} onClick={submitForm} /></div>
                        :
                          <div><img src={IMAGES.editIcon} className={styles.Icon}  onClick={editPermission} /></div>
                      }
                  </div>
                  <ul
                    style={{
                      listStyle: "none",
                      paddingInlineStart: 0,
                      display: "grid",
                      gap: 16,
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {predefinedRolesData.map((role) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <fieldset disabled={!isEditPermission} >                        
                          <CheckBox name="predefinedRoles" value={role.id} label={role.name} />
                        </fieldset>
                      </li>
                    ))}
                  </ul>
                  <div>
                  </div>
                  {/* {isAlert && (
                     <div
                     style={{
                       zIndex: 20,
                       position: "fixed",
                       bottom: 0,
                       left: 0,
                       width: "100%",
                     }}
                   >
                    <Alert
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                   />
                   </div>
                  )} */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ExpandableArea>
    </div>
  );
};

const VFUSerViewer = (props) => {

  const { userList, isLoading,predefinedRoles } = props;
  let [searchKeyValue, setSearchKeyValue] = useState("");
 
  const getSearchKeyValue = (e) => {
    setSearchKeyValue(e.target.value);
  };

  const filterData = (key, value) => {
    let searchKey = searchKeyValue.trim();
    let type=USER_TYPE.INTERNAL;
    let custID="";
    let next=null;
    props.getUser(type,custID,searchKey,next);
  };

  const onKeyDownHandler = (e) => {   // filter data on enter event
    if (e.key === "Enter"){
      filterData();  
    }
  };

  useEffect(() => {
    let searchKey = "";
    let type=USER_TYPE.INTERNAL;
    let custID="";
    let next=null;
    props.getUser(type,custID,searchKey,next);
    props.getPredefinedRoles("internal");
  }, []);

  return (
    <React.Fragment>
      <ChildContainer>
        <VfHeader
          getSearchKeyValue={getSearchKeyValue}
          searchKeyValue={searchKeyValue}
          filterData={filterData}
          onKeyDownHandler={onKeyDownHandler}
        />
        {isLoading && <Loader />}
        {
          !isLoading && userList.length==0?
          <EmptyContainer>No User Found</EmptyContainer>:<></>
        }
        <InfiniteScroll
          dataLength={userList?.length} //This is important field to render the next data
          next={() => {
            return props.getUser(USER_TYPE.INTERNAL, "", searchKeyValue.trim(), props.next);
          }}
          hasMore={props.next}
          loader={
              <Loader />
          }
        >
          {userList?.map((userData) => (
            <UserList
              data={userData}
              predefinedRolesData={predefinedRoles}
              updateUserPermissions={props.updateUserPermissions}
              openAlertPopup={props.openAlertPopup}
            />
          ))}
        </InfiniteScroll>
      </ChildContainer>
    </React.Fragment>
  );
};

export default VFUSerViewer;
