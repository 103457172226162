import React, { useEffect, useState } from "react";
import { Container, ChildContainer } from "../../../components/Container/Containers";
import NewService from "../add/newService.container";

import BannerImage from "../bannerImage/BannerImage";
import { Breadcrumbs } from "../../../components/breadcrumbs/Breadcrumbs";
import { Tabs } from "../../../components/tabs/Tabs";
import { MY_SERVICE_TABS } from "../../../utils/constants/serviceConstants";
import LiveServices from "../../liveServices/liveServices.container";
import SectionGuard from "../../../hoc/guards/SectionGuard";
import CAN from '../../../casl/can';
import { Action, Resource } from '../../../casl/constants';
import { USER_TYPE } from "../../../utils/constants/userConstants";
import NewRequestViewerContainer from "./newRequestViewer/NewRequestViewer.container";
import {connect} from "react-redux";
import { openNewServiceForm,closeNewServiceForm,setServiceActiveTab,setIsAmendMode } from "../../../redux/actions/newService/service.actions";


const ServicesViewer = (props) => {
  let _label=props.myRights?.userType==USER_TYPE.INTERNAL?"Connectivity":"My Connectivity";

  const breadcrumbsOption = [
    { label: "Home", link: "/dashboard" },
    { label: _label, link: "" }
  ];
 // const [activeTab, setActiveTab] = useState("");
  const [tabOptions, setTabOptions] = useState([]);

 useEffect(()=>{
  let demandTabName=props.myRights?.userType==USER_TYPE.INTERNAL?MY_SERVICE_TABS.DEMANDS:MY_SERVICE_TABS.MY_DEMANDS // dynamic tab name

  if(CAN(Action.LIST, Resource.REQUEST) && CAN(Action.LIST, Resource.LIVE_SERVICES)){
    setTabOptions([MY_SERVICE_TABS.LIVE_SERVICES, demandTabName]);
  }else if(CAN(Action.LIST, Resource.LIVE_SERVICES)){
    props.setServiceActiveTab(MY_SERVICE_TABS.LIVE_SERVICES);
  }else if(CAN(Action.LIST, Resource.REQUEST)){
    props.setServiceActiveTab(MY_SERVICE_TABS.demandTabName);
  }
 },[])

  const getActiveTab = (tab) => {
    props.setServiceActiveTab(tab);
    //setActiveTab(tab);
  }
  let [initialValues, setInitialValues] = useState({});


  const newServiceRequest = () => {
    setInitialValues({}); // open blank form
    props.setIsAmendMode(false); //     
    props.openNewServiceForm({});
  }


 


  return (
    <Container>
      <BannerImage isInternalUser={props.myRights?.userType==USER_TYPE.INTERNAL?true:false} showPopup="true" openForm={newServiceRequest} canCreateNewService={CAN(Action.CREATE, Resource.REQUEST)} isAmendMode={props.isAmendMode}/>
      <ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
      </ChildContainer>
      {
       CAN(Action.LIST, Resource.REQUEST) && CAN(Action.LIST, Resource.LIVE_SERVICES)?
       <Tabs option={tabOptions} defaultActiveTab={props.serviceActiveTab} getActiveTab={getActiveTab} />:<></>
      }
     
        <NewService initialValue={initialValues} fromLiveService={false}></NewService>
      


      <ChildContainer>
        {
          props.serviceActiveTab === MY_SERVICE_TABS.MY_DEMANDS || props.serviceActiveTab === MY_SERVICE_TABS.DEMANDS ?
            <NewRequestViewerContainer /> :
            props.serviceActiveTab === MY_SERVICE_TABS.LIVE_SERVICES ?
            <LiveServices />:<></>
        }
      </ChildContainer>
    </Container>
  )
};
const mapStateToProps = (state) => {    
  return {
    serviceActiveTab:state.newService.service.serviceActiveTab,
    myRights:state.myRights.myRights,
    isAmendMode:state.newService.service.isAmendMode
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
      openNewServiceForm: (payload) => {
          return dispatch(openNewServiceForm(payload))
      },
      closeNewServiceForm: () => {
          return dispatch(closeNewServiceForm())
      },
      setServiceActiveTab: (tab) => {
        return dispatch(setServiceActiveTab(tab))
      },
      setIsAmendMode: (mode) => {
        return dispatch(setIsAmendMode(mode))
    }   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesViewer);