import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../components/Container/Containers";
import { Banner } from "../../components/banner/Banners";
import { Breadcrumbs } from "../../components/breadcrumbs/Breadcrumbs";
import IMAGES from "../../assets/images/images";
import { Tabs } from "../../components/tabs/Tabs";
import CustomerViewer from "./customer/list/CustomerViewer.container";
import VFUSerViewer from "./vfUser/list/VFUserViewer.container";


const UserManagement = () => {
  const [activeTab, setActiveTab] = useState("");
  
  const breadcrumbsOption = [
    { label: "User Management", link: "" }
  ];
  const tabOptions = {
    CUSTOMER: "Customer",
    VF_GROUP_USER: "VF Group User",
  }
  const tabOption = [tabOptions.CUSTOMER, tabOptions.VF_GROUP_USER];

  const getActiveTab = (tab) => {
    setActiveTab(tab);
  }

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"User Management"}></Banner>
      {/* <ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
      </ChildContainer> */}
      <Tabs option={tabOption} defaultActiveTab={tabOptions.CUSTOMER} getActiveTab={getActiveTab} />
      <ChildContainer>
        {
          activeTab===tabOptions.CUSTOMER?
           <CustomerViewer/>:
          activeTab===tabOptions.VF_GROUP_USER?
         <VFUSerViewer/>:<></>
        }
      </ChildContainer>
    </Container>
  )
};

export default UserManagement;
