import { FETCH_PRE_DEFINED_ROLES } from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
    roles:[]
};

const reducer = (state=initialState, action)=>{  
    switch(action.type){
        case FETCH_PRE_DEFINED_ROLES.REQUEST:
            return {...state,roles:[],isLoading:true}
        case FETCH_PRE_DEFINED_ROLES.FAILURE:
            return {...state,roles:[],isLoading:false}
        case FETCH_PRE_DEFINED_ROLES.SUCCESS:           
            return {...state,roles:action.data,isLoading:false}        
        default:
            return state
    }   
}

export default reducer;
