import {combineReducers} from "redux";

import myRights from "./userManagement/myRights.reducer";
import newService from "./newService/index.reducer";
import servicesList from "./newService/servicesList.reducer";
import diameterDashboard from "./dashboard/diameter/index.reducer"
import weatherMapDashboard from "./dashboard/weatherMap/index.reducer"
import sigtranDashboard from "./dashboard/sigtran/index.reducer"
import genericInformation from "./genericInformation/genericInformation.reducer";
import liveServices from "./liveServices/liveServices.reduce";
import newuser from "./userManagement/newUser.reducer";
import userViewer from "./userManagement/userViewer.reducer";
import predefinedRoles from "./userManagement/predefinedRoles.reducer";
import customerList from "./userManagement/customer.reducer"
import CustomerDetail from "./userManagement/customerDetails.reducer"
import submitCustomerDetails from "./userManagement/submitNewCustomer.reducer"
import serviceDetail from "./newService/serviceDetail.reducer";
import contactUs from "./contactUs/contactUs.reducer";
import verifyEmail from "./userManagement/verifyEmail.reducer";
import forgotPassword from "./userManagement/forgotPassword.reducer";
import alert from "./alert/alert.reducer";

export default combineReducers({
    myRights,
    newService,
    servicesList,
    diameterDashboard,
    weatherMapDashboard,
    sigtranDashboard,
    genericInformation,
    liveServices,
    newuser,
    userViewer,
    predefinedRoles,
    serviceDetail,
    customerList,
    submitCustomerDetails,
    contactUs,
    CustomerDetail,
    verifyEmail,
    forgotPassword,
    alert

})