import React, { useRef } from "react";
import styles from "./navBar.module.css";
import logo from "../../assets/images/logo.png";
import { logOut } from "../../authActions/authActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CAN from "../../casl/can";
import { Action, Resource } from "../../casl/constants";
import IMAGES from "../../assets/images/images";
import Overlaydropdown from "../../components/overlaydropdown/Overlaydropdown";
import { connect } from "react-redux";
import { MY_SERVICE_TABS } from "../../utils/constants/serviceConstants";
import { setServiceActiveTab } from "../../redux/actions/newService/service.actions";

import Notification from "../notification/Notification";
import { USER_TYPE } from "../../utils/constants/userConstants";


function Navbar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("/dashboard");
  const [initials, setInitials] = useState("");

  const overlayRef = useRef();

  const gotoPage = (path) => {
    navigate(path);
    setSelectedItem(path);
  };

  let Items = [
    {
      path: "/dashboard",
      label: "Dashboard",
      canAccess: CAN(Action.LIST, Resource.POP),
    },
    {
      path: "/connectivity",
      label: props.myRights?.userType==USER_TYPE.INTERNAL?"Connectivity":"My Connectivity",
      canAccess:
        CAN(Action.LIST, Resource.LIVE_SERVICES) ||
        CAN(Action.LIST, Resource.REQUEST),
    },
    {
      path: "/insights",
      label: "Insights",
      canAccess: CAN(Action.GET, Resource.DIAMETER),
    },
    /*{
      path: "/incident-management",
      label: "My Tickets",
      canAccess:true
    },*/
    {
      path: "/user-management",
      label: "User Management",
      canAccess:
        (CAN(Action.LIST, Resource.CUSTOMER) &&
          CAN(Action.LIST, Resource.RIGHTS)) ||
        CAN(Action.GET, Resource.CUSTOMER),
    },
    {
      path: "/contact-us",
      label: "Contact Us",
      canAccess: CAN(Action.CREATE, Resource.QUERY),
    },
  ];

  // useEffect(() => {
  //   navigate("/dashboard");
  // }, [navigate]);

  useEffect(() => {
    const displayName = props.myRights?.displayName
        .split(' ')
        .filter(word => isNaN(word) && word)
        .filter((_, i, arr) => i === 0 || i === arr.length - 1)
        .map(word => word[0])
        .join('')
        .toUpperCase();
      setInitials(displayName);
  }, []);

  useEffect(() => {
    setSelectedItem(location.pathname);
    
    // set default tab live service 
    if(location.pathname != "/connectivity" && !location.pathname.startsWith("/demands")){
        if(CAN(Action.LIST, Resource.REQUEST) && CAN(Action.LIST, Resource.LIVE_SERVICES)){
              if(props.serviceActiveTab != MY_SERVICE_TABS.LIVE_SERVICES){
                props.setServiceActiveTab(MY_SERVICE_TABS.LIVE_SERVICES);
              }
        }
    }
  }, [location, navigate]);

  return (
    <>
      <div className={styles.navmain}>
        <ul className={styles.navul}>
          <img className={styles.logo} src={logo} alt="Logo" />
          {Items.map((item) =>
            item.canAccess ? (
              <li key={item.path} className={styles.navli}>
                <button
             className={`${styles.btnNavItems} 
             ${
              item.path === selectedItem ||
               ((item.path === "/connectivity" &&
                 (location.pathname.startsWith("/demands/")))
               ) ||  ((item.path === "/insights" &&
               (location.pathname.startsWith("/insights/")))
               )
                 ? styles.selected
                 : ""
             }`}
                  onClick={() => gotoPage(item.path)}
                >
                  {item.label}
                </button>
              </li>
            ) : (
              <></>
            )
          )}
        </ul>

        <div className={styles.navicon}>
          {/* <div>
            <button
              className={styles.logoutbtn}
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </button>
          </div> */}      
          {/* <div className={styles.notification}>
            <Notification/>
            </div>     */}
          <div className={styles.usericon}>
            <Overlaydropdown
              ref={overlayRef}
              initials={initials}    
              headerText="User Profile"
            >
             
             <div className={styles.userDiv}>
                <div className={styles.userlabel}>Name</div> 
                <div className={styles.userdetail}>{props.myRights?.displayName}</div>
              </div>
              <div className={styles.userDiv}>
                <div className={styles.userlabel}>Email</div> 
                <div className={styles.userdetail}>{props.myRights?.email}</div>
              </div>
              <div>
            <button
              className={styles.logoutbtn}
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </button>
          </div>
            </Overlaydropdown>
          </div>
        </div>
      </div>  
    </>
  );
}

const mapStateToProps = (state) => {
  return {
      myRights:state.myRights.myRights,
      serviceActiveTab:state.newService.service.serviceActiveTab,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      setServiceActiveTab: (tab) => {
        return dispatch(setServiceActiveTab(tab))
    }  
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
