import { MY_SERVICE_TABS } from "../../../utils/constants/serviceConstants";
import {CLOSE_NEWSERVICE_FORM,OPEN_NEWSERVICE_FORM,SET_ACTIVE_TAB_SERVICES, SET_IS_AMEND_MODE} from "../../actions/actionTypes"

const initialState = {
    isServiceFromOpen: false,
    serviceActiveTab:MY_SERVICE_TABS.LIVE_SERVICES   ,
    isAmendMode : false,
    initialValue: {}
    
};

const reducer = (state = initialState, action) => {  
    switch (action.type) {
        case CLOSE_NEWSERVICE_FORM:
            return { ...state, isServiceFromOpen:false, initialValue: {}};
        case OPEN_NEWSERVICE_FORM:  
        console.log("initialValue in action",action)          
            return { ...state, isServiceFromOpen:true, initialValue:action.payload};
        case SET_ACTIVE_TAB_SERVICES:
            return { ...state, serviceActiveTab:action.tab};
        case SET_IS_AMEND_MODE:
            return { ...state, isAmendMode:action.isAmendMode};
        default:
            return state
    }
}

export default reducer;