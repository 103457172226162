const createActionTypes = (prefix)=> {
    return ['REQUEST', 'SUCCESS', 'FAILURE', 'CACHED'].reduce((acc, type) => {
      return { ...acc, [type]: `${prefix}_${type}` };
    }, {});
};
/************************* ROLES  *********************/
export const FETCH_MYRIGHTS= createActionTypes("FETCH_MYRIGHTS");
export const UPDATE_MYRIGHTS ="UPDATE_MYRIGHTS";
export const UPDATE_IS_LOGGED_IN ="UPDATE_IS_LOGGED_IN";

/************************* SERVICE  *********************/
export const OPEN_NEWSERVICE_FORM= "OPEN_NEWSERVICE_FORM";
export const CLOSE_NEWSERVICE_FORM= "CLOSE_NEWSERVICE_FORM";
export const SET_ACTIVE_TAB_SERVICES= "SET_ACTIVE_TAB_SERVICES";
export const SET_IS_AMEND_MODE= "SET_IS_AMEND_MODE";
export const SAVE_SERVICE= "SAVE_SERVICE";
export const EDIT_SERVICE= "EDIT_SERVICE";
export const DELETE_SERVICE= "DELETE_SERVICE";
export const CLEAR_SERVICE= "CLEAR_SERVICE";
export const SUBMIT_SERVICE_REQUEST= createActionTypes("SUBMIT_SERVICE_REQUEST");
export const FETCH_SERVICES_LIST = createActionTypes("FETCH_SERVICES_LIST");
export const UPDATE_SERVICE_STATUS=createActionTypes("UPDATE_SERVICE_STATUS");
export const DOWNLOAD_SERVICES_LIST = createActionTypes("DOWNLOAD_SERVICES_LIST");
/*********************************************************************** */
export const FETCH_LOCATIONS = createActionTypes("FETCH_LOCATIONS");
export const FETCH_CUSTOMERS = createActionTypes("FETCH_CUSTOMERS")
export const FETCH_SERVICE_DETAIL = createActionTypes("FETCH_SERVICE_DETAIL");
export const FETCH_SERVICES = createActionTypes("FETCH_SERVICES");

/************************* COMMENT  *********************/
export const OPEN_COMMENT_FORM= "OPEN_COMMENT_FORM";
export const CLOSE_COMMENT_FORM= "CLOSE_COMMENT_FORM";
export const ADD_COMMENT_FORM = createActionTypes("ADD_COMMENT_FORM");
export const FETCH_COMMENTS = createActionTypes("FETCH_COMMENTS");
export const DOWNLOADS = createActionTypes("DOWNLOADS");
/******************IMAGE UPLOADER ******************** */

export const UPLOAD_ATTACHMENT = createActionTypes("UPLOAD_ATTACHMENT");
/************************************DIAMETER*********************************** */
export const FETCH_DIAMETER_INOUT_MESSAGE = createActionTypes("FETCH_DIAMETER_INOUT_MESSAGE");
export const FETCH_DIAMETER_INOUT_MESSAGE_BY_Node= createActionTypes("FETCH_DIAMETER_INOUT_MESSAGE_BY_Node")
export const UPDATE_DIAMETER_INOUT_MESSAGE_FILTER = "UPDATE_DIAMETER_INOUT_MESSAGE_FILTER";
export const UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER= "UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER";
export const FETCH_DIA_FINALTYPE_FINALDIST= createActionTypes("FETCH_DIA_FINALTYPE_FINALDIST");
export const FETCH_DIA_SIGNODE_CONNECTIONID= createActionTypes("FETCH_DIA_SIGNODE_CONNECTIONID");
export const FETCH_SIG_FINALTYPE_FINALDIST= createActionTypes("FETCH_SIG_FINALTYPE_FINALDIST");
export const FETCH_SIG_SIGNODE_CONNECTIONID= createActionTypes("FETCH_SIG_SIGNODE_CONNECTIONID");
/*--------Weather MAp----------------------------------------------*/
export const FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER= createActionTypes("FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER");
export const UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER="UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER";
export const FETCH_WEATHERMAP_FILTER_VALUES= createActionTypes("FETCH_WEATHERMAP_FILTER_VALUES");
export const FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER= createActionTypes("FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER");
export const UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER="UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER";




/************************************SIGTRAN*********************************** */
export const FETCH_SIGTRAN_INOUT_MESSAGE = createActionTypes("FETCH_SIGTRAN_INOUT_MESSAGE");
export const FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node= createActionTypes("FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node")
export const UPDATE_SIGTRAN_INOUT_MESSAGE_FILTER = "UPDATE_SIGTRAN_INOUT_MESSAGE_FILTER";
export const UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER= "UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER";

/***************************SERVICE STATUS******************************************** */

export const OPEN_SERVICE_STATUS_FORM= "OPEN_SERVICE_STATUS_FORM";
export const CLOSE_SERVICE_STATUS_FORM= "CLOSE_SERVICE_STATUS_FORM";
/***************************Generic Information******************************************** */

export const FETCH_IPX_POPS=createActionTypes("FETCH_IPX_POPS");
/************************Live Services  */
export const FETCH_LIVE_SERVICES=createActionTypes("FETCH_LIVE_SERVICES");
export const FETCH_LIVE_SERVICES_FILTER=createActionTypes("FETCH_LIVE_SERVICES_FILTER");
export const DOWNLOAD_LIVE_SERVICES_LIST = createActionTypes("DOWNLOAD_Live_SERVICES_LIST");

/*********************** UserManagement VF_Users_Group    */
export const FETCH_USERS_LIST=createActionTypes("FETCH_USERS_LIST");
export const NEW_USER=createActionTypes("NEW_USER");
export const FETCH_PRE_DEFINED_ROLES=createActionTypes("PRE_DEFINED_ROLES");
export const UPDATE_USER_PERMISSIONS=createActionTypes("UPDATE_USER_PERMISSIONS");
export const VERIFY_EMAIL=createActionTypes("VERIFY_EMAIL");
export const FORGOT_PASSWORD=createActionTypes("FORGOT_PASSWORD");

// /*************************** CUSTOMER ******************************************** */
export const FETCH_CUSTOMER_LIST = createActionTypes('FETCH_CUSTOMER_LIST');
export const SUBMIT_CUSTOMER_DETAILS = createActionTypes('SUBMIT_CUSTOMER_DETAILS');  
export const FETCH_CUSTOMER_DETAILS = createActionTypes('FETCH_CUSTOMER_DETAILS');

// /*************************** Contact Us ******************************************** */
export const SUBMIT_CONTACT_US_DETAILS = createActionTypes('SUBMIT_CONTACT_US_DETAILS');

/******************************  Alert Message  *****************************************/
export const OPEN_ALERT_POPUP= "OPEN_ALERT_POPUP";
export const CLOSE_ALERT_POPUP= "CLOSE_ALERT_POPUP";

