import Select from 'react-select'

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled} = state
       
        return (
            {...provided, pointerEvents: 'initial', backgroundColor: isDisabled ? "#CCCCCC" : "#fff",cursor: isDisabled ? 'not-allowed' : 'pointer', borderRadius: 5, height: 48,borderWidth: 1, borderColor: hasError? "#CD3500" : "#979797"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#0D0D0D", fontFamily: "VodafoneRg-Regular", fontSize:18}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#0D0D0D", fontFamily: "VodafoneRg-Regular", fontSize:18}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    dropdownIndicator: (provided, state)=> ({...provided, color: "#333333"}),
    
    //container: (provided, state)=> ({...provided, width: 500,}),
    container: (provided, state)=> {
        const {selectProps: {customStyles}} = state
        return (
            {...provided, ...customStyles}
        );
    },
}
let obj={
    width:500 
}
const DropDown = ({options,placeholder, onChange,value, hasError,isDisabled=false,customStyles=obj })=>{
    return (
        <Select 
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}
            customStyles={customStyles}
            isDisabled={isDisabled}
           />
    );
}

export default DropDown;