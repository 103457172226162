import React from 'react'
import styles from './myIncidentViewer.module.css'
import IMAGES from '../../../../assets/images/images';
import { useNavigate } from 'react-router-dom';
import AddComment from '../../addComment/addComment.container';


const IncidentList = ({  }) => {

  const navigate = useNavigate();

  const gotoIncidentDetail = (requestId) => {
    navigate(`/incidentDetail/${requestId}`);
  }

    return (
      <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
        <div className={styles.header}>
                <div> <span  className={styles.SRid} onClick={()=>gotoIncidentDetail()}>SR-20240719-21</span><span className={styles.status}>Submitted</span></div>
                <div className={styles.onhoverdiv}>
                  <div> <div className={styles.tooltip}> <img className={styles.infoCircle}src={IMAGES.infoCircle}/>
                     <span className={styles.tooltiptext} >Respond within 48 hours</span>
                         </div>
                       </div>
                  <div><AddComment source={'incList'}></AddComment></div>
                </div>
        </div>
        <div className={styles.summary}><span className={styles.issue}>Issue Summary -</span><span className={styles.summarydata}>Lorem ipsum dummy text lorem.  Lorem ipsum dummy text .</span></div>
          <div className={styles.titleContainer}>
          
          <div className={styles.DetailsDiv}>Submitted on<span className={styles.data}> 07 Aug 2023</span></div>
          <div className={styles.border}></div>
          <div className={styles.DetailsDiv}>Signalling Issue<span className={styles.data}> sigtran</span></div>
          
         
          </div>
        
        </div>
       
      </div>
    );
  };
  

const MyIncidentViewer = () => {
    return (
        <div>
          <IncidentList/>
        </div>
    )
}
  
export default MyIncidentViewer;
