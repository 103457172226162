import React from "react";
import styles from "./search.module.css";
import searchIcon from "../../assets/images/search.png";

export function Search({ style = "", ...rest }) {
  return (
    <div className={styles.searchContainer}>
      <input
        className={`${styles.input} ${style}`} 
        type="search"
        placeholder={rest.placeholder}
        value={rest.value}
        onChange={rest.getSearchKeyValue }      
        {...rest} 
      />
      <img className={styles.imageIcon} src={searchIcon} alt="searchIcon"  onClick={() => {
              rest.filterData('searchKey', '')
            }} />
    </div>
  );
}
