import React, { useState } from "react";
import styles from "./overlaydropdown.module.css";
import IMAGES from "../../assets/images/images";

const Overlaydropdown = React.forwardRef(({initials, headerText, children }, ref)  => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  React.useImperativeHandle(ref, () => ({
    handleClick
  }));

  return (
    <div>
      <button className={styles.applybtn} onClick={handleClick}>
          <div className={styles.userIcon} >{initials} </div>
      </button>

        {isOpen && (
          <div className={styles.overlayInnerbox}>
            <div className={styles.headerContainer}>
                <div className={styles.header}>{headerText}</div>
                <div className={styles.close} >
                  <img src={IMAGES.close} onClick={handleClick} alt="close" height={"24"} width={"24"}/>
                </div>
            </div>
           
            {children}
          </div>
        )}

    </div>

  );
});

export default Overlaydropdown;