import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./addTicket.module.css";
import { Submit,BackButton } from "../../../../components/button/buttons";
import { FormFieldLabel as Label ,FormErrorLabel as ErrorLabel, FormFieldLabel} from "../../../../components/label/Labels";
import { useField } from "formik";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import * as Yup from "yup";
import IMAGES from "../../../../assets/images/images";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import DropDown from "../../../../components/dropDown/DropDown";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";

const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
  };
  const ButtonWrapper = ({ children }) => {
    return <div className={styles.buttonContainer}>{children}</div>;
  };

 
  const TextField = ({ label, placeholder, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    //const inputStyle ={ width:"250px"};
    return (
      <FieldWrapper>
        <div>
          <Label text={label} />
          <Asterisk />
        </div>
        <TextInput {...field} {...props} placeholder={placeholder} style={styles.box} />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };
  let dropObj = {
    width: "auto"
  }
  const SelectField = ({ title, options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <FieldWrapper>
        <div>
          <Label text={title} /><Asterisk />
        </div><div className={styles.inputbox}>
        <DropDown
          options={options}
          onChange={({ label, value }) => {
            props.onChange(value);
            helpers.setValue(value);
          }}
          value={
            ((options?.length > 0) && field.value)
              ? options.find((option) => option.value === field.value)
              : ""
          }
          hasError={hasError}
          isDisabled={props.isDisabled}
          customStyles={dropObj}
        /></div>
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };

  const TextArea = (props) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
      ? styles.inputError
      : null;
    return (
      <FieldWrapper>
          <div>
            <Label text={props.text} /><Asterisk />
          </div>
          <FormTextArea 
            placeholder={props.placeholder}
            label={props.label}
            id={props.id}
            name={props.name}
            style={styles.txta} 
            {...field}
          />
           {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };
  const ImageUpload = ({
    setFieldValue,
    value,
    label = "Supporting Attachments",
    ...props
  }) => { 
    return (
      <FieldWrapper>
        <Label text={label} optional={false} />
        <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue}  />
        <ErrorMessage name ={props.name}>
            {(msg)=><ErrorLabel text={msg} />}
        </ErrorMessage>
      </FieldWrapper>
    );
  };
 const types = [
    { label: "KPI Dgradation", value: "KPI Dgradation" },
    { label: "Call Failure", value: "Call Failure" },
    { label: "Roaming Vois Issue", value: "Roaming Vois Issue" },
    ];
const validationSchema = Yup.object().shape({
   
  });

  const AddTicket = (props) => {
    const { predefinedRoles } = props;
    const [show, setShow] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const close = () => setShow(false);
  
    const closeForm = () => {
        setShow(false);
    };
  
    const servicesData = {
      "services": [
          {
              "value": "DIAMETER",
              "label": "DIAMETER"
          },
          {
              "value": "DNS",
              "label": "DNS"
          },
          {
              "value": "SIGTRAN",
              "label": "SIGTRAN"
          },
          {
              "value": "TRANSPORT",
              "label": "TRANSPORT"
          }
      ]
    }
  
    
    const config = {
      "KPI Dgradation": [
        { label: "Sub Category", name: "Sub Category", type: 'select', options: servicesData.services },
        { label: "Current Value", name: "Current Value", type: 'input' },
        { label: "Expected Value", name: "Expected Value", type: 'input' },
        { label: "Problem statement", name: "Problem statement", type: 'textarea' },
        { label: "Date Time", name: "Date Time", type: 'select', options: servicesData.services },
        { label: "Priority", name: "Priority", type: 'select', options: servicesData.services },
        { label: "Attachment", name: "Attachment", type: 'imageupload' },
        { label: "Additional Information", name: "Additional Information", type: 'textarea' },
       
      ],
      "Call Failure": [
        { label: "Sub Category", name: "Sub Category", type: 'select', options: servicesData.services },
        { label: "Calling Number", name: "Calling Number", type: 'input' },
        { label: "Called Number", name: "Called Number", type: 'input' },
        { label: "Problem statement", name: "Problem statement", type: 'textarea' },
        { label: "Domestic Call Service", name: "Domestic Call Service", type: 'select', options: servicesData.services },
        { label: "Priority", name: "Priority", type: 'select', options: servicesData.services },
        { label: "Date Time", name: "Date Time", type: 'select', options: servicesData.services },
        { label: "Attachment", name: "Attachment", type: 'imageupload' },
        { label: "Additional Information", name: "Additional Information", type: 'textarea' },
         ],
      "Roaming Vois Issue": [
       { label: "Domestic Call Service", name: "Domestic Call Service", type: 'select', options: servicesData.services },
        { label: "Calling Number", name: "Calling Number", type: 'input' },
        { label: "Called Number", name: "Called Number", type: 'input' },
        { label: "Problem statement", name: "Problem statement", type: 'textarea' },

        { label: "Priority", name: "Priority", type: 'select', options: servicesData.services },
        { label: "Date Time", name: "Date Time", type: 'select', options: servicesData.services },
        { label: "MSRN", name: "MSRN", type: 'input' },
        { label: "Attachment", name: "Attachment", type: 'imageupload' },
        { label: "Additional Information", name: "Additional Information", type: 'textarea' },
      ],
    };
    
    const renderFormElements = (config, setFieldValue) => {
      return config.map((element, index) => {
        switch (element.type) {
          case 'input':
            return <div className={styles.Container}><TextField key={index} label={element.label} name={element.name} /></div>;
           
           
          
          case 'select':
            return (
              <SelectField key={index}  
              
              title={element.label}
              name={element.name}
              options={element.options}
              onChange={() => {
              }}
              />
            );
          default:
            return null;
        }
      });
    };
    const renderfullwidth = (config, setFieldValue) => {
      return config.map((element, index) => {
        switch (element.type) {
        case 'imageupload':
              return (
                <ImageUpload
                  key={index}
                  name={element.name}
                  value={element.value}
                  setFieldValue={setFieldValue}
                  optional={false}
                />
              );
           
          case 'textarea':
            return (
              <TextArea
              key={index}
              text={element.label}
              stylee={styles.txta}
              placeholder="type here"
              
              name={element.name}
            />
            );
        
          default:
            return null;
        }
      });
    };
    return (
      <div>
        <button onClick={() => setShow(true)} className={styles.addButton}>Raise New Incident</button>
        {show && (
          <Sidebar isOpen={show} headerTxt="Raise New Incident" onClose={close}>
            <Formik
              initialValues={{ formType: "Call Failure" }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                console.log("form values", values);
                props.openAlertPopup({
                  message: "Incident details form submitted successfully!",
                  image: IMAGES.success
                });
              }}
            >
              {({ isValid, dirty, setFieldValue, values, handleChange, handleBlur, resetForm }) => (
               
               <Form className={styles.form}>
               <div className={styles.Container}> 
                 <SelectField
                   title={"Failure Type"}
                   name="failureType"
                   options={types}
                   onChange={(value) => {
                     setSelectedType(value);
                     setFieldValue("failureType", value);
                   }}
                 />
                 {renderFormElements(config[selectedType] || [], setFieldValue)}
                 </div>
                 {renderfullwidth(config[selectedType] || [], setFieldValue)}
               <hr className={styles.separator} />
               <ButtonWrapper>
                 <BackButton
                   className={styles.backButton}
                   style={styles.submit}
                   title={"Back"}
                   closePopup={close}
                 />
                <Submit
                  className={styles.submitButton}
                  style={styles.submit}
                  title={"Submit"}
                  closePopup={close}
                  disabled={!isValid || !dirty}
                />
              </ButtonWrapper>
             </Form>
              )}

            </Formik>
          </Sidebar>
        )}
      </div>
    );
  };
   
export default AddTicket;