import React, { useId, useState, useEffect } from "react";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import styles from "./CustomerDetail.module.css";
// import NewCustomer from "../add/NewVFUser";
import IMAGES from "../../../../assets/images/images";
import { Banner } from "../../../../components/banner/Banners";
import NewUser from "../addUser/NewUser.container";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import { Loader } from "../../../../components/loader/Loader";
// import { Alert } from "../../../../components/alert/alerts";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";

const Switch = ({ isChecked }) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={isChecked} onChange={() => {}} />
      <span className={styles.slider}></span>
    </label>
  );
};
const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const UserList = ({ data, predefinedRolesData, updateUserPermissions, openAlertPopup }) => {
  let [isAlert, setIsAlert] = useState(false);
  let [isEditPermission, setIsEditPermission] = useState(false);
  let [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success,
  });

  const validationSchema = Yup.object().shape({
    predefinedRoles: Yup.array().min(1),
  });
  let initialValues = {
    predefinedRoles:
      data?.predefinedRoles.length > 0 ? data.predefinedRoles : [],
  };

  const editPermission = () => {
    setIsEditPermission(!isEditPermission);
  };

  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Contact Name</span>
        <p>{data.displayName}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{data.email}</p>
      </div>

      {/* <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Mobile Number</span>
        <p>{data.phoneNumber}</p>
      </div> */}

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Onboarded On</span>
        <p>{formatSecondsToDate(data.createdAt)}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Status </span>
        <p>
          {""}Inactive{" "}
          <span className={styles.spanSwitch}>
            <Switch isChecked={data.status === "active" ? true : false} />
          </span>
          Active
        </p>
      </div>
      <ExpandableArea>
        <div style={{ width: "100%" }}>
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (values, { resetForm }) => {
                values.userType = data.userType;
                setShowLoader(true);
                updateUserPermissions(values, data.uid)
                  .then((result) => {
                    if (result.status) {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "User permissions updated successfully.",
                      //   image: IMAGES.success,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "User permissions updated successfully!",
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    } else {
                      let error_msg = result?.error;
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: error_msg,
                      //   image: IMAGES.error,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error_msg,
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    }
                  })
                  .catch((error) => {
                    setIsEditPermission(!isEditPermission);
                    // setMessage({
                    //   message: "Something went wrong.",
                    //   image: IMAGES.error,
                    // });
                    // setIsAlert(true);
                    openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error
                    });
                    setShowLoader(false);
                  });
              }}
            >
              {({ isValid, dirty, submitForm }) => (
                <Form className={styles.form}>
                  <div className={styles.sectionContainer}>
                    <div className={styles.sectionTitle}>Modules Assigned</div>
                    {showLoader ? (
                      <div className={styles.loaderContainer}>
                        <Loader style={styles.loaderContainer} />
                      </div>
                    ) : isEditPermission ? (
                      <div>
                        <img
                          src={IMAGES.save}
                          className={styles.Icon}
                          onClick={submitForm}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={IMAGES.editIcon}
                          className={styles.Icon}
                          onClick={editPermission}
                        />
                      </div>
                    )}
                  </div>
                  <ul
                    style={{
                      listStyle: "none",
                      paddingInlineStart: 0,
                      display: "grid",
                      gap: 16,
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {predefinedRolesData.map((role) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <fieldset disabled={!isEditPermission}>
                          <CheckBox
                            name="predefinedRoles"
                            value={role.id}
                            label={role.name}
                          />
                        </fieldset>
                      </li>
                    ))}
                  </ul>
                  <div></div>
                  {/* {isAlert && (
                    <div
                      style={{
                        zIndex: 20,
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <Alert
                        image={message.image}
                        message={message.message}
                        onClose={setIsAlert}
                      />
                    </div>
                  )} */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ExpandableArea>
    </div>
  );
};

const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span>View More</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.expanded}>
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>

      {children}
    </div>
  );
};

const VodafoneUserList = ({ vfData }) => {
  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>
          {vfData?.pref == 0
            ? "Primary Contact Name"
            : "Additional Contact Name"}
        </span>
        <p>{vfData?.name}</p>
      </div>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{vfData?.email}</p>
      </div>
      {/*<div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Onboarded on</span>
        <p>22 May 2020</p>
      </div>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Status </span>
        <p>
          Inactive{" "}
          <span className={styles.spanSwitch}>
            <Switch />
          </span>{" "}
          Active
        </p>
  </div>*/}
    </div>
  );
};

const CustomerDetail = ({
  customerId,
  customer,
  isLoadingCustomer,
  getDetails,
  onBack,
  myRights,
  next,
  ...props
}) => {
  const { userList, isLoading, predefinedRoles, openAlertPopup } = props;
  
  useEffect(() => {
    let searchKey = "";
    let type = USER_TYPE.CUSTOMER;
    let custID = customerId;
    let next=null;
    props.getUser(type, custID, searchKey,next);
    props.getPredefinedRoles("customer");
    getDetails(customerId);
    window.scrollTo(0,0);
  }, []);

  return (
    <Container>
      {myRights.userType === USER_TYPE.CUSTOMER ? (
        <Banner image={IMAGES.banner} text={"User Management"}></Banner>
      ) : null}
      {isLoadingCustomer  ? (
        <Loader />
      ) : (
        <ChildContainer>
          <div className={styles.customer}>
            {myRights.userType === USER_TYPE.INTERNAL ? (
              <img
                className={styles.icon}
                onClick={onBack}
                src={IMAGES.chevronredleft}
                alt=""
              />
            ) : null}

            <div className={styles.textCustomer}>{customer?.displayName}</div>
          </div>
          <div>
            <div className={styles.CustomerContainer}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>TADIG ID</span>
                <p>{customer?.cpName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Onboarding Date</span>
                <p>{formatSecondsToDate(customer?.createdAt)}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Primary Contact Name</span>
                <p spandata>{customer?.primaryContact?.displayName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Email Address</span>
                <p spandata>{customer?.primaryContact?.email}</p>
              </div>
            </div>
          </div>

          <div
            data-vf-user={
              myRights.userType === USER_TYPE.INTERNAL ? true : false
            }
            className={styles.detailsContainer}
          >
            <div>
              <div className={styles.textuser}>Vodafone Account Manager Contacts</div>
             {/* <div className={styles.sharedEmailContainer}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Shared Mailbox</span>
                <p>{customer?.vfSharedMailDL}</p>
              </div>
            </div> */}
              {customer?.vfPOC.map((vfData) => (
                <VodafoneUserList vfData={vfData} />
              ))}
            </div>
            <div>
              <div className={styles.header}>
                <div className={styles.text}>Customer Details</div>
                <NewUser cpName={customerId} primaryContact={customer?.primaryContact}/>
              </div>
              { userList?.length ? (
                <InfiniteScroll
                  dataLength={userList?.length} //This is important field to render the next data
                  next={() => {
                    return props.getUser(
                     USER_TYPE.CUSTOMER,
                     customerId,
                      "",
                      next
                    );
                  }}
                  hasMore={next}
                  loader={
                      <Loader />
                  }
                >
                  {userList?.map((userData) => (
                    <UserList
                      data={userData}
                      predefinedRolesData={predefinedRoles}
                      updateUserPermissions={props.updateUserPermissions}
                      openAlertPopup= {openAlertPopup}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                // <div className={styles.emptyContainer}>No users found</div>
                <EmptyContainer>No User Found</EmptyContainer>
              )}
            </div>
          </div>
        </ChildContainer>
      )}
    </Container>
  );
};

export default CustomerDetail;
