import React, { useState } from "react";
import { Sidebar } from "../../components/sidebar/Sidebar";
import IMAGES from "../../assets/images/images";
import { Formik, Form ,ErrorMessage,useField} from "formik";
import * as Yup from 'yup';
import styles from "./contactUs.module.css";
import Asterisk from "../../components/asterisk/Asterisk";
import { FormTextArea, TextInput } from "../../components/input/inputs";
import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel} from "../../components/label/Labels";
import { BackButton, Submit } from "../../components/button/buttons";
import FileAttachment from "./fileAttachment/fileAttachment.container";
// import { Alert } from "../../components/alert/alerts";
import * as DOMPurify from 'dompurify';
import Section from "../../components/Section/Section";
import { Container } from "../../components/Container/Containers";

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
  ? styles.inputError
  : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        <Asterisk />
      </div>
      <TextInput {...field} {...props} placeholder={placeholder} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const TextArea = (props) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
        <div>
          <Label text={props.text} />
          <Asterisk />
        </div>
        <FormTextArea
          placeholder={props.placeholder}
          label={props.label}
          id={props.id}
          name={props.name}
          style={inputStyle} 
          {...field}
        />
         {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => { 
  return (
    <FieldWrapper>
      <Label text={label} optional={false} />
      <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue}/>
      <ErrorMessage name ={props.name}>
          {(msg)=><ErrorLabel text={msg} />}
      </ErrorMessage>
    </FieldWrapper>
  );
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().max(100,"Subject must be at most 100 characters")
    .required('Subject is required'),
  body: Yup.string().max(2000,"Comment must be at most 2000 characters")
    .required('Comment is required'),
  files:Yup.array().max(3)

});

const ContactUs =(props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });
  return (
    <Container>
    <div className={styles.maincontainer}>
      <div className={styles.subcontainer}>
        <div className={styles.container}>
          <label className={styles.title}>Contact Us</label>
      <Formik
        initialValues={
          {
            subject: "",
            body: "",
            files: [],
        }
        }
        validationSchema={validationSchema}
        onSubmit= {(values,{ resetForm }) => {
          setIsSubmit(true);     
          let obj = { "files": [] };
          obj.files = values?.files.map(({ fileSize, ...rest }) => rest);
          obj.subject = DOMPurify.sanitize(values?.subject);
          obj.body = DOMPurify.sanitize(values?.body);         
          props.addComment(obj).then((result) => {        
            if (result.status) {
              // setMessage({
              //   message: "Query submitted successfully",
              //   image: IMAGES.success
              // });
              //setIsAlert(true);
              props.openAlertPopup({
                message: "Thank you for contacting us. We are reviewing your request and we’ll get in touch as soon as possible.",
                image: IMAGES.success,
                messageTimeout:10000
              });          
              setIsSubmit(false);
              resetForm();
            } else {
              let error_msg = result?.error;
              // setMessage({
              //   message: error_msg,
              //   image: IMAGES.error
              // });
              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error
              });  
             setIsSubmit(false);
            }
          })
          .catch((error) => {
            // setMessage({
            //   message: "Something went wrong.",
            //   image: IMAGES.error
            // });
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error
            }); 
            setIsSubmit(false);
          });
        }}
      >
        {({ isValid, dirty, values, setFieldValue }) => (
          <Form>
            <TextField
              placeholder="Type here"
              label="Subject"
              name="subject"
              type="text"
            />
             <div className={styles.commentValidation}>100 characters</div>
            <TextArea
              text="Comment" 
              className={styles.textarea}
              placeholder="Type here"
              label="body"
              name="body"
            />
             <div className={styles.commentValidation}>2000 characters</div>
            <ImageUpload
              name="files"
              value={values.files}
              setFieldValue={setFieldValue}
              optional={false}              
            />
            
            <hr className={styles.separator} />
            <div className={styles.button}>
              <Submit
                style={styles.submit}
                title="Submit"
                id="submit"
                isSubmitting={isSubmit}
                //disabled={!formik.dirty || !formik.isValid}
              />
            </div>
          </Form>
           )}
        </Formik>
          {/* {isAlert && (
                  <div
                    style={{
                      zIndex: 20,
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    <Alert 
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                    />
                  </div>
                )} */}
        </div>
      </div>
    </div>
    </Container>
  );
}

export default ContactUs;



