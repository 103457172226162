/** *****************************Actions************************* */
export const Action = {
  UPDATE: "update",
  DELETE: "delete",
  GET: "get",
  LIST: "list",
  CREATE: "create",
  STATUS_UPDATE:"statusupdate",
  DOWNLOAD: "download",
  ADD: "add",
};

/** *****************************Resources************************* */
export const Resource = {
  IAM: "iam",
  CUSTOMER:"customer",
  RIGHTS:"rights",
  USERS:"users",
  ROLES:"roles",
  PREDEFINED_ROLES:"predefinedRoles",
  DIAMETER:"diameter",
  SIGTRAN:"sigtran",
  WEATHERMAPS:"weathermaps",
  REQUEST:"request",
  LIVE_SERVICES:"liveservices",
  COMMENT:"comment",
  ATTACHMENT:"attachment",
  POP:"pop",
  QUERY:"query"
};

