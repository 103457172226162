import AddTicket from "./addTicket";
import {connect} from "react-redux";
import {openAlertPopup} from "../../../../redux/actions/Alert/alert.action";

const mapStateToProps = (state) => { 
    return {
                     
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
          }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTicket);
