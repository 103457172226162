import { Formik,useField } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { Sidebar } from "../../../../components/sidebar/Sidebar";
import { TextInput } from "../../../../components/input/inputs";
import { BackButton, Submit } from "../../../../components/button/buttons";
import { FormFieldLabel } from "../../../../components/label/Labels";
import Asterisk from "../../../../components/asterisk/Asterisk";
// import { Alert } from "../../../../components/alert/alerts";


import styles from "./NewCustomer.module.css";
import IMAGES from "../../../../assets/images/images";
import {
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { capitalizeWords } from "../../../../utils/utils";

const validationSchema = Yup.object().shape(
  {
    organisationName: Yup.string().max(100, "Organisation name must be at most 100 characters").required("Required"),
    tadigId: Yup.string().max(20, "TadigId must be at most 20 characters").required("Required"),
    customerPrimaryContactName: Yup.string().max(100, "Name must be at most 100 characters").required("Required"),
    customerPrimaryEmailAddress: Yup.string()
      .email("Invalid email").max(100, "Email must be at most 100 characters")
      .required("Required"),
    vodafonePrimaryContactName: Yup.string().max(100, "Name must be at most 100 characters").required("Required"),
    vodafonePrimaryEmailAddress: Yup.string()
      .email("Invalid email").max(100, "Email must be at most 100 characters")
      .required("Required"),
    vodafoneAdditonalContactName: Yup.string().when('vodafoneAdditonalEmailAddress', {
      is: (vodafoneAdditonalEmailAddress) => vodafoneAdditonalEmailAddress && vodafoneAdditonalEmailAddress.trim().length > 0,
      then: () => Yup.string().trim().max(100, "Name must be at most 100 characters").required("Required"),
      otherwise: () => Yup.string().trim().max(100, "Name must be at most 100 characters")
    }),
    vodafoneAdditonalEmailAddress: Yup.string().when('vodafoneAdditonalContactName', {
      is: (vodafoneAdditonalContactName) => vodafoneAdditonalContactName && vodafoneAdditonalContactName.trim().length > 0,
      then: () => Yup.string().email("Invalid email").max(100, "Email must be at most 100 characters").required("Required"),
      otherwise: () => Yup.string().email("Invalid email").max(100, "Email must be at most 100 characters")
    }),
    vodafoneSharedMailbox: Yup.string().email("Invalid email").max(100, "Email must be at most 100 characters").required("Required"),

  }, ['vodafoneAdditonalContactName', 'vodafoneAdditonalEmailAddress']
)

const TextField = ({disabled=false,...props}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <div className={props.containerStyle}>
      <div>
        <FormFieldLabel
          htmlFor={props.htmlFor}
          text={props.text}
        />
        {props.isMandatory?<Asterisk />:<></>}
      </div>
      <TextInput
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        disabled={disabled}
        {...field}  style={inputStyle} 
        onChange={props.onChange}
      />
       {hasError ? <ErrorLabel text={meta.error} /> : null}
    </div>
  )
}

const NewCustomer = ({ submitRequest, submitting, getCustomerList,openAlertPopup   }) => {
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState({
    message: "",
  image: IMAGES.success});

  const close = () => setShowAddCustomer(false);

  return (
    <>
      <button
        onClick={() => setShowAddCustomer(true)}
        className={styles.addButton}
      >
        Add New Customer
      </button>

      {showAddCustomer ? (
        <Sidebar
          isOpen={setShowAddCustomer}
          headerTxt="Add New Customer"
          onClose={close}
        >
          <div className={styles.container}>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                organisationName: "",
                tadigId: "",
                customerPrimaryContactName: "",
                customerPrimaryEmailAddress: "",
                vodafonePrimaryContactName: "",
                vodafonePrimaryEmailAddress: "",
                vodafoneAdditonalContactName: "",
                vodafoneAdditonalEmailAddress: "",
                vodafoneSharedMailbox: "vfgroupfunc.mailboxccp@vodafone.com",
              }}
              onSubmit={(values) => {
                let _vfPOC=[
                  {
                    name: values.vodafonePrimaryContactName,
                    email: values.vodafonePrimaryEmailAddress,
                    pref: 0,
                  }
                ]
              if( values.vodafoneAdditonalContactName && values.vodafoneAdditonalEmailAddress )
                  _vfPOC.push(
                    {
                      name: values.vodafoneAdditonalContactName,
                      email: values.vodafoneAdditonalEmailAddress,
                      pref: 1,
                    },
                )
                submitRequest({
                  orgName: values.organisationName,
                  cpName: values.tadigId?.toUpperCase(),
                  custPrimContactDispName: values.customerPrimaryContactName,
                  custPrimContactEmail: values.customerPrimaryEmailAddress,
                  vfPOC: _vfPOC,
                  vfSharedMailDL: values.vodafoneSharedMailbox,
                }).then(
                  (value) => {
                    if (value.status) {
                      // setMessage({
                      //   message: "New customer added successfully",
                      //   image: IMAGES.success
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "New customer added successfully",
                        image: IMAGES.success
                      });
                      setShowAddCustomer(false);
                      getCustomerList();
                    } else {
                      let error = value?.error;
                      // setMessage({
                      //   message: error,
                      //   image: IMAGES.error
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error,
                        image: IMAGES.error
                      });
                      setShowAddCustomer(false);
                      getCustomerList();
                    }
                  },
                ).catch((error) => {
                  // setMessage({
                  //   message: "Something went wrong",
                  //   image: IMAGES.error
                  // });
                  // setIsAlert(true);
                  openAlertPopup({
                    message: "Something went wrong",
                    image: IMAGES.error
                  });
                  setShowAddCustomer(false);
                });
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue,isValid, values, dirty }) => (
                <>
                  <form
                    id="new-customer-form"
                    onSubmit={handleSubmit}
                    className={styles.form}
                  >
                    <TextField
                      htmlFor="organisation-name"
                      text="Organisation Name"
                      isMandatory={true}
                      name="organisationName"
                      id="organisation-name"
                      placeholder="Input text"
                      containerStyle={styles.field}
                      onChange={handleChange}
                    />
                    <TextField
                      htmlFor="tadig-id"
                      text="TADIG ID"
                      isMandatory={true}
                      id="tadig-id"
                      placeholder="Input text"
                      name="tadigId"
                      containerStyle={styles.field}
                      onChange={handleChange}
                    />
                    <TextField
                      htmlFor="customer-primary-contact-name"
                      text="Customer Primary Contact Name"
                      isMandatory={true}
                      id="customer-primary-contact-name"
                      placeholder="Input text"
                      name="customerPrimaryContactName"
                      containerStyle={styles.field}
                      onChange={(e)=>{
                      setFieldValue("customerPrimaryContactName",capitalizeWords(e.target.value));
                      }}
                    />
                    <TextField
                      htmlFor="customer-primary-email-address"
                      text="Customer Primary Contact Email"
                      isMandatory={true}
                      id="customer-primary-email-address"
                      placeholder="Input text"
                      name="customerPrimaryEmailAddress"
                      containerStyle={styles.field}
                      onChange={handleChange}
                    />
                    <TextField
                      htmlFor="vodafone-primary-contact-name"
                      text="Vodafone Primary Contact Name"
                      isMandatory={true}
                      id="vodafone-primary-contact-name"
                      placeholder="Input text"
                      name="vodafonePrimaryContactName"
                      containerStyle={styles.field}
                      onChange={(e)=>{
                        setFieldValue("vodafonePrimaryContactName",capitalizeWords(e.target.value));
                        }}
                    />
                    <TextField
                      htmlFor="vodafone-primary-email-address"
                      text="Vodafone Primary Contact Email"
                      isMandatory={true}
                      id="vodafone-primary-email-address"
                      placeholder="Input text"
                      name="vodafonePrimaryEmailAddress"
                      containerStyle={styles.field}
                      onChange={handleChange}
                    />
                    <TextField
                      htmlFor="vodafone-additional-contact-name"
                      text="Vodafone Additional Contact Name"
                      isMandatory={false}
                      id="vodafone-additional-contact-name"
                      placeholder="Input text"
                      name="vodafoneAdditonalContactName"
                      containerStyle={styles.field}
                      onChange={(e)=>{
                        setFieldValue("vodafoneAdditonalContactName",capitalizeWords(e.target.value));
                        }}
                    />
                    <TextField
                      htmlFor="vodafone-additional-email-address"
                      text="Vodafone Additional Contact Email"
                      isMandatory={false}
                      id="vodafone-additional-email-address"
                      placeholder="Input text"
                      name="vodafoneAdditonalEmailAddress"
                      containerStyle={styles.field}
                      onChange={handleChange}
                    />
                    <TextField
                      htmlFor="vodafone-ipx-shared-mailbox"
                      text="Vodafone IPX Shared Mailbox"
                      isMandatory={true}
                      id="vodafone-ipx-shared-mailbox"
                      placeholder="Input text"
                      name="vodafoneSharedMailbox"
                      disabled={true}
                      containerStyle={`${styles.field} ${styles.expanded}`}
                      onChange={handleChange}
                    />

                    {/* <Separator /> */}
                  </form>
                  <footer className={styles.footer}>
                    {/* <BackButton title="Back" closePopup={close} />
                   {submitting ?<Loader/> : <Submit title="Submit" form = "new-customer-form" disabled={!isValid} />} */}
                    <BackButton
                      className={styles.backButton}
                      style={styles.submit}
                      title={"Back"}
                      closePopup={close}
                      // onClose={close}
                    />
                    <Submit
                      id="saveButton"
                      title={"Submit"}
                      isSubmitting={submitting}
                     // disabled={!isValid || !dirty}
                      form="new-customer-form"
                    />
                  </footer>
                </>
              )}
            </Formik>
          </div>
        </Sidebar>
      ) : null}

      {/* {isAlert && (
        <div
          style={{
            zIndex: 20,
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <Alert
            image={message.image}
            message={message.message}
            onClose={setIsAlert}
          />
        </div>
      )} */}

    </>
  );
};

export default NewCustomer;
