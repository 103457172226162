import React, { useState,useEffect} from "react";
import styles from "./tabs.module.css"


export const Tabs = (props) => {
    const [activeTab, setActiveTab] = useState(props.defaultActiveTab);
    useEffect(() => {
        props.getActiveTab(activeTab);        
    }, [activeTab]);
    const handleTab = (item) => {
        setActiveTab(item);      
    }
    return (
        <div className={styles.conatiner}>
            {
                props.option.map((item,index) => {
                    let _class = `${styles.item}`
                    if (activeTab === item) {
                        _class = `${styles.item} ${styles.active}`
                    }
                    return (
                        <div key={index} className={_class} onClick={() => handleTab(item)}>
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}